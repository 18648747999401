@import 'dark-theme';

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'WantedM54';
  src: url('/assets/font/WantedM54.ttf') format('truetype');
}

* {
  max-width: 500px;
}

html {
  font-family: 'WantedM54', serif;
  font-size: 1.1em;
  color: #fedf9f;
  background-color: #031219;
  height: 100%;
}

body, .mat-card, .mat-app-background, .mat-drawer, .mat-drawer-container {
  margin: 0;
  color: #fedf9f;
  background-color: #031219;
  height: 100%;
}

/* FORMS */
.mat-form-field-label {
  color: #fedf9f;
}

.mat-form-field {
  margin-bottom: 8px;
}

//.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
//    background-color: #66977f;
//}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #fedf9f;
  opacity: 1 !important;
}

.mat-form-field-outline {
  background-color: rgba(0, 0, 0, 0.5);
}

/* SNACKBARS */
.red-snackbar, .red-snackbar button {
  background-color: #de8813;
  color: #fedf9f;
}

.green-snackbar, .green-snackbar button {
  background-color: #66977f;
  color: #fedf9f;
}

.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6 {
  font-family: 'WantedM54', serif;
}

h1.bleu {
  font-size: 1.4em;
}

h1.bleu, h2.bleu, h3.bleu, h4.bleu {
  letter-spacing: .18rem;
  background: -webkit-linear-gradient(90deg, rgba(81, 110, 103, 1) 0%, rgba(11, 152, 187, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 4px;
}

h1.orange, h2.orange, h3.orange, h4.orange {
  letter-spacing: .18rem;
  background: -webkit-linear-gradient(90deg, rgba(225, 138, 22, 1) 0%, rgba(127, 129, 95, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 4px;
}

img[logo] {
  position: fixed;
  left: 0;
  top: 260px;
  width: 100%;
  height: 440px;
  pointer-events: none;
}

div[overlay] {
  position: fixed;
  left: 0;
  top: 260px;
  background: rgb(74, 129, 150);
  //background: radial-gradient(circle, rgba(74, 129, 150, 0.3) 0%, rgba(3, 18, 25, 0.3) 67%);
  background: radial-gradient(circle, rgba(74, 129, 150, 0.2) 0%, rgba(3, 18, 25, 0.9) 67%);
  //background: radial-gradient(circle, rgba(74, 129, 150, 1) 0%, rgba(3, 18, 25, 1) 47%);
  width: 100%;
  height: 440px;
  pointer-events: none;
}

.mat-raised-button.mat-neutral {
  background-color: #000f1f;
  font-family: 'WantedM54', serif;
  border: 1px solid #f9e0a4;
  height: 56px;
  line-height: 56px;
  padding: 0;
  border-radius: 0;

  & h2 {
    margin-block: 0;
    line-height: 56px;
    font-size: 1.8em;
  }
}

.mat-raised-button.mat-neutral.small {
  border: 1px solid #f9e0a4;
  height: 36px;
  line-height: 30px;
  margin-bottom: 4px;
}

.mat-card {
  border-radius: 0 !important;
  background: transparent;
}

.mat-accordion>.mat-expansion-panel-spacing:last-child, .mat-accordion>*:last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
  margin-bottom: 20px !important;
}

.mat-dialog-container {
  background-color: #031219;
  color: #fedf9f;
  border: 1px solid #fedf9f;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end{
  border-radius: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border-radius: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end, .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-radius: 1px !important;
}

.mat-divider {
  border-top-color: rgb(225, 138, 22);
}

.example-header-image {
  background-image: url('assets/images/Logo_fond.jpg');
  background-size: cover;
}

mat-card.article {
  background-color: rgba(0, 0, 0, 0.7);
  border-color: #f9e0a4;
  color: #f9e0a4;
  border-width: 1px;
  border-radius: 0 !important;
  border-style: solid;
  margin: 8px 0 16px 0;
}

.mat-expansion-panel-header, .mat-expansion-panel-header.mat-expanded {
  min-height: 48px;
  height: auto;
}
